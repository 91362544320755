import { graphql } from "gatsby"

import { Article, Section } from "../components/containers"
import { ArchiveItem } from "../components/blog"
import { ArchivePagination } from "../components/pagination"
import { ArticleTitle } from "../components/headings"
import Seo from "../components/Seo"

const ArchiveTemplate = ({
  data: {
    allBlogPost: { edges: posts }
  },
  pageContext: { currentPage, numPages },
  location
}) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = isFirst
    ? null
    : currentPage === 2
    ? `/blog/`
    : `/blog/${currentPage - 1}/`
  const nextPage = isLast ? null : `/blog/${currentPage + 1}/`
  const pageTitle = "Blog & Commentary"

  return (
    <>
      <Seo
        pageTitle={pageTitle}
        pageDescription="Author’s blog"
        pageUrl={location.pathname}
      />
      <Article>
        <ArticleTitle title={pageTitle} />
        <Section padding={true} semantic={false}>
          {posts.map(({ node: post }) => (
            <ArchiveItem
              key={post.id}
              slug={post.slug}
              title={post.title}
              date={post.date}
              author={post.author}
            />
          ))}
        </Section>
        <Section padding={true} heading="Blog Archive Pagination">
          <ArchivePagination prev={prevPage} next={nextPage} />
        </Section>
      </Article>
    </>
  )
}

export default ArchiveTemplate

export const archiveTemplateQuery = graphql`
  query ArchiveTemplateQuery($skip: Int!, $limit: Int!) {
    allBlogPost(
      filter: { published: { eq: true } }
      sort: { fields: date, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          title
          date
          slug
          author
        }
      }
    }
  }
`
